<template>
  <div
    id="pull-to-refresh"
    class="d-flex justify-content-center align-items-center pull-to-refresh-wrapper position-relative hidden"
  >
    <feather-icon
      icon="LoaderIcon"
      size="20"
      class="mr-50 text-primary pull-to-refresh-icon"
    />

    <span class="pull-to-refresh-text text-capitalize text-primary">
      Hold To Refresh..
    </span>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  components: {},

  setup() {
    // App Name
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
