<!-- PWAPrompt.vue -->
<template></template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formToastification } from '@core/mixins/ui/formToastification'
import { handleWebview } from '@core/mixins/ui/handleWebview'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BrowserNotification',

  components: {
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [formToastification, handleWebview],

  data: () => ({
    shown: false,
    loading: false,

    browserToken: null,

    toastPosition: 'bottom-right',
    logo: require('@/assets/images/logo/logo.svg'),
  }),

  async created() {
    if (this.canSeeBrowserNotification) {
      await this.$OneSignal.init({
        appId: process.env.VUE_APP_ONESIGNAL_API_KEY,
        serviceWorkerParam: { scope: '/onesignal/' },
        serviceWorkerPath: '/onesignal/OneSignalSDKWorker.js',
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: true,
        },
      })
    }
  },

  mounted() {
    setTimeout(async () => {
      this.$OneSignal.User.PushSubscription.addEventListener(
        'change',
        (event) => {
          if (event.current.optedIn && !event.previous.optedIn) {
            this.browserToken = event.current.token
              ? event.current.token
              : event.previous.token
            this.saveBrowserToken()
          }
        }
      )

      await this.$OneSignal.User.PushSubscription.optIn()
    }, 1000)
  },

  methods: {
    ...mapActions('settings', ['updateSessionPushNotificationSetting']),

    saveBrowserToken() {
      if (!this.user.loggedIn) {
        this.$store.commit('auth/setBrowserToken', this.browserToken)
        return
      }

      this.enablePushNotifications()
    },

    enablePushNotifications() {
      let data = {
        enabled: true,
        device_name: `Android`,
        binding_type: 'android_browser',
        binding_address: this.browserToken,
      }

      this.updateSessionPushNotificationSetting(data)
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),

    canSeeBrowserNotification() {
      let standalone = window.matchMedia('(display-mode: standalone)').matches,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent),
        android = /Android/i.test(userAgent)

      return standalone && android && this.$OneSignal != null
    },
  },

  watch: {},
}
</script>

<style scoped></style>
