import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import '@/@fake-db/db'

// Global Components
import './global-components'
import './global-directives'
import './plugins'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/vue-meta'
// import '@/libs/acl'

import './registerServiceWorker'
import * as Sentry from '@sentry/vue'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://cf3d9230937e4e2bab2a55b2a62111eb@o4505234090295296.ingest.sentry.io/4505234172280832',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
  })
}

// Libraries
import _ from 'lodash'
Vue.set(Vue.prototype, '_', _)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')
require('./bootstrap')

Vue.config.productionTip = false
Object.defineProperty(Vue.prototype, '$_', { value: _ })

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
