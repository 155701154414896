<template>
  <div>
    <b-card-text
      class="mb-2 text-danger"
      v-if="form.errors.has('internalError')"
    >
      {{ form.errors.get('internalError') }}
    </b-card-text>

    <validation-observer ref="formValidator">
      <b-form class="auth-settings-form" @submit.prevent="submit">
        <b-row>
          <b-col sm="12">
            <validation-provider
                #default="{ errors }"
                name="rating"
                vid="rating"
                rules="required"
            >
            <b-form-rating
                v-model="form.rating"
                variant="warning"
                inline
                no-border
                size="lg"
                class="m-0 p-0"
            />
             <div class="d-flex w-100">
               <small class="text-danger">{{ errors[0] }}</small>
             </div>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <!-- first name -->
            <b-form-group label="Share your feedback below and help us improve (Optional) " label-for="title">
              <validation-provider
                #default="{ errors }"
                name="message"
                vid="message"
              >
                <b-form-textarea
                  id="message-area"
                  v-model="form.message"
                  rows="3"
                  :state="
                    form.message
                      ? form.message.length <= maxChar && errors.length <= 1
                      : null
                  "
                  class="char-textarea"
                  :class="form.message.length > maxChar ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-left font-small-2"
                  :class="
                    form.message.length < maxChar
                      ? ''
                      : form.message.length === maxChar
                      ? 'bg-warning'
                      : 'bg-danger'
                  "
                >
                  <span class="char-count">{{ form.message.length }}</span> /
                  {{ maxChar }}
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-50">
          <!-- buttons -->
          <b-col
            class="d-flex justify-content-end align-items-center"
            cols="12"
          >
            <b-button
                v-if="showCancelBtn"
              variant="outline-secondary"
              type="button"
              size="sm"
              @click.prevent="cancelAction"
            >
              Not Now
            </b-button>

            <b-button
              variant="primary"
              class="ml-1"
              type="submit"
              size="sm"
              :disabled="loading"
            >
              Submit
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormRating,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { loadingAnimation } from '@core/mixins/ui/loadingAnimation'
import Form from '@/plugins/Form'
import {mapActions, mapGetters} from 'vuex'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'AppRating',
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormRating,

    // validations
    ValidationProvider,
    ValidationObserver,

    //3rd Party
  },

  props: {
    showCancelBtn: {
      type: Boolean,
      default: false,
    }
  },

  directives: {
    Ripple,
  },

  mixins: [loadingAnimation],

  data() {
    return {
      loading: false,
      maxChar: 1000,

      form: new Form(
        {
          message: '',
          rating: null,
        },
        this.$api
      ),

      required,
    }
  },

  beforeMount() {
    this.init()
  },

  mounted() {
  },

  methods: {
    ...mapActions('appReviews', ['createReview']),
    ...mapActions('auth', ['fetchUser']),

    init() {
      this.form.rating = this.user.data.rating
    },

    async submit() {
      let validation = await this.$refs.formValidator.validate()

      if (!validation) {
        return false
      }

      let rating = this.form.rating

      if (
        this.form.message != null &&
        this.form.message.length > this.maxChar
      ) {
        this.$refs.formValidator.setErrors({
          message: [
            `The review can not have more than ${this.maxChar} characters.`,
          ],
        })
        return false
      }

      this.loading = true

      await this.createReview(this.form)

      if (this.form.errors.any()) {
        this.loading = false
        this.formSubmitted(false)
        this.$refs.formValidator.setErrors(this.form.errors.all())
        return false
      }

      this.$refs.formValidator.reset()
      this.loading = false

      this.user.data.rating = this.form.rating = rating

      this.formSubmitted()
      this.success()
    },


    formSubmitted(success = true) {
      this.$emit('formSubmitted', success)
    },

    success() {
      this.$swal({
        title: 'Thank you for your feedback!',
        text: 'You can leave us other review anytime.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    cancelAction() {
      this.$emit('cancelAction')
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),

  },

  watch: {
  },
}
</script>

<style lang="scss">
</style>
