import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import appReviews from './app-reviews'
import verticalMenu from './vertical-menu'
import auth from './auth'
import profile from './profile'
import notifications from './notifications'
import settings from './settings'
import collections from './collections'
import users from './users'
import contacts from './contacts'
import invitations from './invitations'
import agendums from './agendums'
import conversations from './conversations'
import agendumMemberships from './agendum-memberships'
import agendumNotes from './agendum-notes'
import agendumMessages from './agendum-messages'
import agendumAttachments from './agendum-attachments'
import agendumActivities from './agendum-activities'
import personalCalendar from './personal-calendar'
import search from './search'
import requests from './requests'
import feed from './feed'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    appReviews,
    verticalMenu,
    auth,
    profile,
    settings,
    collections,
    agendums,
    conversations,
    users,
    notifications,
    contacts,
    invitations,
    agendumMemberships,
    agendumNotes,
    agendumMessages,
    agendumActivities,
    agendumAttachments,
    personalCalendar,
    search,
    requests,
    feed,
  },
  strict: process.env.DEV,
})
