import axios from 'axios'

window._ = require('lodash')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true
// window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const origin = console.error
console.error = (error) => {
  if (/Loading chunk [\d]+ failed/.test(error.message)) {
    try {
      document.dispatchEvent(new CustomEvent('chunkErrorEncountered'))
    } catch (error) {
      console.error('Failed to handle loading-chunk error.', error)
    }
  } else {
    origin(error)
  }
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'

if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
  window.Pusher = require('pusher-js')

  window.Echo = new Echo({
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          const instance = axios.create({
            baseURL: `https://${process.env.VUE_APP_API_DOMAIN}`,
            withCredentials: true,
          })

          instance
            .post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
              withCredentials: true,
            })
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error)
            })
        },
      }
    },

    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKET_BROADCAST_HOST,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
    wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
    forceTLS: true,
    disableStats: process.env.VUE_APP_WEBSOCKET_STATS,
    enabledTransports: ['ws', 'wss'],
    encrypted: process.env.VUE_APP_WEBSOCKET_ENCRYPTED == 'true',
  })
}
