import axios from 'axios'
import store from '@/store'

const defaultEndpoint = '/collections'

const state = {
  collections: {},
  featuredCollections: {},

  userFeaturedCollections: {},
  userCollections: {},
}

const getters = {
  collections: (state) => (data) => {
    return state.collections
  },

  featuredCollections: (state) => (data) => {
    return state.featuredCollections
  },

  userCollections: (state) => (data) => {
    return state.userCollections
  },

  userFeaturedCollections: (state) => (data) => {
    return state.userFeaturedCollections
  },
}

const actions = {
  async fetchCollections({ commit }, data) {
    let { payload, username, id } = data
    let endpoint = `${defaultEndpoint}`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      if (payload && payload.search == null) {
        commit('setCollections', { username, data: null })
      }

      return response
    }

    if (payload && payload.search == null) {
      commit('setCollections', { username, data: response.data })
    }

    return response
  },

  async fetchFeaturedCollections({ commit }, data) {
    let { payload, username, id } = data
    let endpoint = `${defaultEndpoint}/featured`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      if (payload && payload.search == null) {
        commit('setFeaturedCollections', { username, data: null })
      }

      return response
    }

    if (payload && payload.search == null) {
      commit('setFeaturedCollections', { username, data: response.data })
    }

    return response
  },

  async createCollection({ dispatch, commit }, form) {
    await form.post(defaultEndpoint, true)

    let response = form._response

    if (!form.errors.any() && response) {
      commit('setCollection', {
        collection: response,
        id: response.id,
      })

      commit('setFeaturedCollections', { username: null, data: null })
    }

    return response
  },

  async updateCollection({ dispatch, commit }, form) {
    await form.post(`${defaultEndpoint}/${form.id}`, true)
    let response = form._response

    if (!form.errors.any() && response) {
      commit('setCollection', {
        collection: response,
        id: response.id,
      })

      commit('setFeaturedCollections', { username: null, data: null })
    }

    return response
  },

  async deleteCollection({ dispatch, commit }, form) {
    let id = form.id

    await form.delete(`${defaultEndpoint}/${id}`)
    let response = form._response

    if (!form.errors.any() && response && response.status) {
      commit('setCollection', { collection: null, id: id })
      commit('setFeaturedCollections', { username: null, data: null })
    }

    return response
  },
}

const mutations = {
  setCollections(state, payload) {
    let { username, data } = payload
    let newData = null

    if (data != null) {
      newData = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
        staticData: data.static ? data.static : null,
      }
    }

    if (username == null) {
      state.collections = newData
    } else {
      state.userCollections = newData
    }
  },

  setFeaturedCollections(state, payload) {
    let { username, data } = payload
    let newData = null

    if (data != null) {
      newData = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    if (username == null) {
      state.featuredCollections = newData
    } else {
      state.userFeaturedCollections = newData
    }
  },

  setCollection(state, data) {
    let keyToUpdate = null
    let { collection, id } = data

    if (_.isEmpty(state.collections)) return

    for (const key in state.collections.data) {
      if (
        state.collections.data[key] &&
        state.collections.data[key].id === id
      ) {
        keyToUpdate = key
        break
      }
    }

    if (collection != null) {
      if (keyToUpdate == null) {
        state.collections.data.push(collection)
      } else {
        state.collections.data[keyToUpdate].attributes = {
          ...collection.attributes,
        }
      }
    } else if (collection == null && keyToUpdate != null) {
      state.collections.data.splice(keyToUpdate, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
