const defaultEndpoint = '/agendum-memberships'

const state = {}

const getters = {}

const actions = {
  updateMembership({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}`)
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
