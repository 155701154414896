import store from '@/store'
import router from '@/router'
import { commit } from 'lodash/seq'

const defaultEndpoint = '/personal-calendar'

const state = {}

const getters = {}

const actions = {
  createCalendarItem({ dispatch, commit }, form) {
    return form.post(defaultEndpoint)
  },

  updateCalendarItem({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}`)
  },

  async deleteCalendarItem({ dispatch, commit }, form) {
    let { id } = form
    await form.delete(`${defaultEndpoint}/${id}`)

    let response = form._response

    if (!form.errors.any() && response) {
    }

    return response
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
