export const handleWebview = {
  data() {
    return {}
  },

  methods: {
    isIos() {
      let userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipod|ipad/.test(userAgent)
    },

    isWebview() {
      let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (standalone && safari) {
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
        }
      }

      return false
    },
  },
  watch: {},
}

export const _ = null
