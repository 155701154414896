import Vue from 'vue'
import 'regenerator-runtime/runtime'
require('es6-promise').polyfill()

import Api from './plugins/api-plugin'

Vue.use(Api)

import UUID from 'vue-uuid'

Vue.use(UUID)

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue)

import AppMetaTags from './plugins/app-meta-tags'

Vue.use(AppMetaTags)

// import VueHtml2Canvas from './plugins/html2canvas'
//
// Vue.use(VueHtml2Canvas)
//
// import VueCapacitor from './plugins/capacitor'
//
// Vue.use(VueCapacitor)
//
//
// import htmlToImage from './plugins/htmlToImage'
//
// Vue.use(htmlToImage)
//
// import Slugify from './plugins/slugify-plugin'
//
// Vue.use(Slugify)
//
// import VueMask from 'v-mask'
//
// Vue.use(VueMask)
//
// import VueImg from 'v-img'
//
// Vue.use(VueImg)

// import VueViewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
//
// Vue.use(VueViewer, {
//   defaultOptions: {
//     title: false,
//     navbar: false,
//     toolbar: {
//       zoomIn: true,
//       zoomOut: true,
//       oneToOne: false,
//       reset: false,
//       prev: false,
//       play: false,
//       next: false,
//       rotateLeft: true,
//       rotateRight: true,
//       flipHorizontal: false,
//       flipVertical: false,
//     },
//     movable: true,
//     scalable: false,
//     zoomOnWheel: false,
//     minZoomRatio: 0.25,
//   },
// })

if (process.env.VUE_APP_FEATURE_AUTO_TRANSLATE === 'true') {
  locizify.init({
    // required
    fallbackLng: 'en',
    backend: {
      projectId: '80ca5707-df02-4f57-bbe7-c7bf5f95a85c',
      referenceLng: 'en',
    },

    // defaults that are set
    bindSavedMissing: true, // will connect backend supporting onSaved and trigger a reload on saved missings
    autorun: true, // setting to false init will return an object with start function
    ele: document.body, // pass in another element if you like to translate another html element
    ignoreTags: ['SCRIPT'], // tags to ignore

    // optional
    ignoreIds: ['ignoreMeId'],
    ignoreClasses: ['ignoreMeClass'],

    // cleanup for keys
    cleanIndent: true, // removes indent, eg. if a p tag spans multiple lines
    ignoreCleanIndentFor: ['PRE', 'CODE'], // ignores cleaning up of indent for those tags needing that extra spaceing
    cleanWhitespace: true, // removes surrounding whitespace from key

    namespace: 'agendum', // set another name - default namespace will be translation
    debug: false,
    saveMissing: false,
  })
}
