import mock from './mock'

/* eslint-disable import/extensions */

// pages
import './data/pages/faq-data'
import './data/pages/tutorials-data'
import './data/pages/pricing-data'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
