import store from '@/store'

const defaultEndpoint = '/feed'

const state = {
  agendumList: {},
  agendumNextPageLink: null,
  agendumFiltersApplied: null,
  agendumListScrollIndex: 0,
  shallFetch: true,
  shallRefreshCounter: 0,
}

const getters = {
  shallFetch: (state) => {
    return state.shallFetch
  },

  shallRefreshCounter: (state) => {
    return state.shallRefreshCounter
  },

  agendumListScrollIndex: (state) => {
    return state.agendumListScrollIndex
  },

  agendumList: (state) => {
    return state.agendumList ? state.agendumList : null
  },

  agendumNextPageLink: (state) => {
    return state.agendumNextPageLink ? state.agendumNextPageLink : null
  },

  agendumFiltersApplied: (state) => {
    return state.agendumFiltersApplied ? state.agendumFiltersApplied : null
  },
}

const actions = {
  async fetchFeed({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchReactions({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/reactions`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async deleteItem({ dispatch, commit }, form) {
    let { id } = form
    await form.delete(`${defaultEndpoint}/${id}`)

    let response = form._response

    if (!form.errors.any() && response) {
    }

    return response
  },

  async makeReaction({ dispatch, commit }, form) {
    let { id } = form
    return await form.post(`${defaultEndpoint}/${id}/make-reaction`)
  },
}

const mutations = {
  setAgendumList(state, data) {
    state.agendumList = !_.isEmpty(data) ? [...data] : null

    if (data == null) {
      state.agendumNextPageLink = null
      state.agendumFiltersApplied = null
    }
  },

  setAgendumInList(state, payload) {
    let { data, id } = payload
    let agendum = null

    if (_.isEmpty(state.agendumList)) {
      return null
    }

    let agendums = [...state.agendumList]

    if (data) {
      state.agendumList = [
        ...agendums.map((item) => {
          if (item.id === id) {
            return data
          }
          return item
        }),
      ]
    } else {
      state.agendumList = [...agendums.filter((item) => item.id !== id)]
    }
  },

  setAgendumListScrollIndex(state, index) {
    state.agendumListScrollIndex = index
  },

  setAgendumNextPageLink(state, data) {
    state.agendumNextPageLink = data
  },

  setAgendumFiltersApplied(state, data) {
    state.agendumFiltersApplied = data
  },

  setShallFetch(state, value) {
    state.shallFetch = value
  },

  setShallRefreshCounter(state, value) {
    state.shallRefreshCounter = value
  },

  incrementShallRefreshCounter(state) {
    state.shallRefreshCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
