<template>
  <div>
    <div :id="lightGalleryId"></div>
  </div>
</template>

<script>
import lightGallery from 'lightgallery'
import lgZoom from 'lightgallery/plugins/zoom'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MediaPreview',

  data() {
    return {
      loading: false,

      plugins: [lgZoom],
      lightGallery: null,
      lightGalleryId: `agendumAppPreviewPhotoLightGallery`,
    }
  },

  mounted() {
    this.initGallery()
  },

  beforeDestroy() {
    if (this.lightGallery) {
      this.lightGallery.destroy()
    }
  },

  methods: {
    initGallery() {
      if (this.lightGallery) return

      this.lightGallery = lightGallery(
        document.getElementById(this.lightGalleryId),
        {
          plugins: this.plugins,
          mode: 'lg-fade',
          licenseKey: process.env.VUE_APP_LICENSE_LIGHT_GALLERY
            ? process.env.VUE_APP_LICENSE_LIGHT_GALLERY
            : ' `0000-0000-000-0000',
          addClass: 'lg-gallery-extra-options',
          dynamic: true,
          download: false,
          dynamicEl: this.lightGalleryItems,
        }
      )

      document
        .getElementById(this.lightGalleryId)
        .addEventListener('lgAfterClose', (event) => {
          this.$store.commit('app/LOAD_PHOTOS_TO_PREVIEW', null)
        })
    },

    updateGallery(slideIndex = null) {
      this.lightGallery.refresh(this.lightGalleryItems)

      if (slideIndex) {
        this.lightGallery.updateSlides(this.lightGalleryItems, slideIndex)
      }
    },

    async openGallery() {
      let index = 0

      this.updateGallery(index)
      this.lightGallery.openGallery(index)
    },
  },

  computed: {
    ...mapGetters('app', ['photosToPreview']),

    lightGalleryItems() {
      if (!_.isEmpty(this.photosToPreview)) {
        return _.map(this.photosToPreview, (item) => {
          return {
            src: item.url,
          }
        })
      }

      return []
    },
  },

  watch: {
    photosToPreview(to, from) {
      if (!_.isEmpty(to)) {
        this.openGallery()
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-zoom.css';

.lg-gallery-extra-options {
  .lg-toolbar {
    .lg-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
</style>
