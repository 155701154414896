export const loadingAnimation = {
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    loading(to, from) {
      this.$store.commit('app/TOGGLE_LOADING_ANIMATION', to)
    },
  },
}

export const _ = null
