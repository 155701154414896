import axios from 'axios'
import store from '@/store'

const defaultEndpoint = '/notifications'

const state = {
  notifications: [],
  newNotificationsCount: 0,
  pendingInvitations: 0,
  shallRefresh: false,
  linkedUsers: [],
}

const getters = {
  notifications: (state) => {
    return state.notifications
  },

  newNotificationsCount: (state) => {
    return state.newNotificationsCount
  },

  pendingInvitations: (state) => {
    return state.pendingInvitations
  },

  shallRefresh: (state) => {
    return state.shallRefresh
  },
}

const actions = {
  async fetchNotifications({ commit }, payload) {
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setNotifications', null)
      return response
    }

    commit('setNotifications', response.data)
    commit('setNewNotificationsCount', response.data.stats.new)
    store.commit(
      'conversations/setNewMessagesCount',
      response.data.stats.messages
    )
    commit(
      'setPendingNotificationsCount',
      response.data.stats.pending_invitations
    )
    commit('setShallRefresh', false)
    return response
  },

  readNotification({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/read`)
  },

  incrementNewNotificationsCount({ commit }) {
    commit('setNewNotificationsCount', state.newNotificationsCount + 1)
  },

  resetNewNotificationsCount({ commit }) {
    commit('setNewNotificationsCount', 0)
  },

  updateNewNotificationsCount({ commit }, count) {
    commit('setNewNotificationsCount', count)
  },

  updateShallRefresh({ commit }, value) {
    commit('setShallRefresh', value)
  },

  incrementPendingNotificationsCount({ commit }) {
    commit('setPendingNotificationsCount', state.pendingInvitations + 1)
  },

  decrementPendingNotificationsCount({ commit }) {
    if (state.pendingInvitations > 0) {
      commit('setPendingNotificationsCount', state.pendingInvitations - 1)
    }
  },

  resetPendingNotificationsCount({ commit }) {
    commit('setPendingNotificationsCount', 0)
  },

  updatePendingNotificationsCount({ commit }, count) {
    commit('setPendingNotificationsCount', count)
  },

  analyseShallRefresh({ commit }, username) {
    if (state.linkedUsers.includes(username)) {
      commit('setShallRefresh', true)
    }
  },
}

const mutations = {
  setNotifications(state, data = []) {
    let notifications = {
      data: !_.isEmpty(data) && data.data ? data.data : null,
      nextPageLink: !_.isEmpty(data) && data.links ? data.links.next : null,
    }

    state.notifications = notifications

    if (!_.isEmpty(notifications.data)) {
      _.forEach(notifications.data, function (notification, index) {
        if (
          notification.relations &&
          notification.relations.user &&
          notification.relations.user.id
        ) {
          if (!state.linkedUsers.includes(notification.relations.user.id))
            state.linkedUsers.push(notification.relations.user.id)
        }
      })
    }
  },

  setNewNotificationsCount(state, data = 0) {
    state.newNotificationsCount = data
  },

  setPendingNotificationsCount(state, data = []) {
    state.pendingInvitations = data
  },

  setShallRefresh(state, value) {
    state.shallRefresh = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
