<!-- =========================================================================================
    File Name: VxTour.vue
    Description: Tour Component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-tour
    name="appTour"
    :steps="steps"
    :callbacks="callbacks"
    :options="options"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <div
            slot="header"
            class="v-step__header d-flex justify-content-between align-items-center"
          >
            <span>{{ step.header.title }}</span>
            <b-button
              size="sm"
              variant="light"
              class="d-flex align-items-center v-step__header-close-btn"
              @click="tour.stop"
            >
              <feather-icon icon="XIcon" size="12" class="cursor-pointer" />
            </b-button>
          </div>

          <div slot="actions" class="tour-actions">
            <div
              slot="actions"
              class="tour-actions d-flex justify-content-between align-items-center"
            >
              <b-button-toolbar>
                <b-button-group class="mr-1">
                  <!-- Skip Button -->
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    class="btn-tour-skip d-flex align-items-center"
                    @click="handleDisable"
                  >
                    <span class="mr-25">Disable</span>
                  </b-button>

                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="btn-tour-skip d-flex align-items-center"
                    @click="tour.finish"
                  >
                    <span class="mr-25">Skip</span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>

              <!-- Previous Button -->
              <b-button
                v-if="tour.currentStep"
                size="sm"
                variant="outline-primary"
                class="mr-1 px-50 d-flex align-items-center"
                @click="tour.previousStep"
              >
                <feather-icon icon="ChevronLeftIcon" size="12" />
                <span class="ml-25 align-middle">Previous</span>
              </b-button>

              <!-- Next Button -->
              <b-button
                v-if="tour.currentStep != tour.steps.length - 1"
                size="sm"
                variant="primary"
                class="btn-tour-next px-50 d-flex align-items-center"
                @click="tour.nextStep"
              >
                <span class="mr-25 align-middle">Next</span>
                <feather-icon icon="ChevronRightIcon" size="12" />
              </b-button>

              <!-- Finish Button -->
              <b-button
                v-if="tour.currentStep == tour.steps.length - 1"
                size="sm"
                variant="primary"
                class="btn-tour-finish px-50 d-flex align-items-center"
                @click="tour.finish"
              >
                <span class="mr-25 align-middle">Finish</span>
                <feather-icon icon="CheckCircleIcon" size="12" />
              </b-button>
            </div>
          </div>
        </v-step>
        <!-- eslint-enable vue/no-use-v-if-with-v-for -->
      </transition>
    </template>
  </v-tour>
</template>

<script>
import { BButton, BButtonGroup, BButtonToolbar } from 'bootstrap-vue'

export default {
  name: 'VxTour',
  components: {
    BButton,
    BButtonGroup,
    BButtonToolbar,
  },
  props: {
    steps: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      callbacks: {
        onFinish: this.handleFinish,
      },
      options: {
        stopOnTargetNotFound: false,
      },
    }
  },

  methods: {
    handleFinish() {
      let cookie = this.$cookies.get('agendum_tour_app')

      if (_.isEmpty(cookie)) {
        cookie = {}
      }

      cookie[this.$route.name] = 'finished'

      this.$cookies.set('agendum_tour_app', cookie)
    },

    handleDisable() {
      this.$tours.appTour.stop()
      document.querySelector('.customizer-toggle').click()
      setTimeout(() => {
        let assistantElement = document.querySelector(
          '.app-customizer-toggle-btn'
        )
        if (assistantElement == null) return

        assistantElement.classList.add('border-primary')
        assistantElement.classList.add('p-1')

        setTimeout(() => {
          assistantElement.click()
        })
      }, 500)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';
@import '~@core/scss/vue/libs/tour.scss';

.dark-layout {
  .v-step__header-close-btn {
    color: $white;
    background-color: $theme-dark-card-bg !important;
    border-color: $theme-dark-border-color !important;
  }
}
</style>
