import store from '@/store'

const defaultEndpoint = '/agendum-activities'

const state = {
  activities: {},
}

const getters = {
  activities: (state) => (agendumId) => {
    return state.activities[agendumId] ? state.activities[agendumId] : null
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchActivities({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setActivities', { data: null, agendumId: payload.agendum_id })
      return response
    }

    commit('setActivities', {
      data: response.data,
      agendumId: payload.agendum_id,
    })

    return response
  },

  checkActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      if (!_.isEmpty(event.data.attributes)) {
        commit('setActivity', {
          activity: event.data,
          activityId: event.data.id,
          agendumId: event.data.agendum_id,
        })
      } else {
        commit('setActivity', {
          activity: null,
          activityId: event.data.id,
          agendumId: event.data.agendum_id,
        })
      }
    }
  },
}

const mutations = {
  setActivities(state, payload) {
    let { data, agendumId } = payload

    let newData = {
      data: !_.isEmpty(data) && data.data ? data.data : null,
      nextPageLink: !_.isEmpty(data) && data.links ? data.links.next : null,
    }

    let activities = { ...state.activities }
    activities[agendumId] = newData
    state.activities = activities
  },

  setActivity(state, data) {
    let keyToUpdate = null
    let { activityId, activity, agendumId } = data

    let activities = { ...state.activities }

    if (
      _.isEmpty(activities[agendumId]) ||
      _.isEmpty(activities[agendumId].data)
    ) {
      if (activity == null) return

      activities[agendumId] = {
        data: [activity],
        nextPageLink: null,
      }

      state.activities = activities
      return
    }

    for (const key in activities[agendumId].data) {
      if (
        activities[agendumId].data[key] &&
        activities[agendumId].data[key].id === activityId
      ) {
        keyToUpdate = key
        break
      }
    }

    if (activity != null) {
      if (
        keyToUpdate == null &&
        activities[agendumId].data[0].attributes.created_at_timestamp <=
          activity.attributes.created_at_timestamp
      ) {
        activities[agendumId].data = [activity, ...activities[agendumId].data]
      } else if (keyToUpdate != null) {
        activities[agendumId].data[keyToUpdate].attributes = {
          ...activity.attributes,
        }
      }
    } else if (activity == null && keyToUpdate != null) {
      activities[agendumId].data.splice(keyToUpdate, 1)
    }

    state.activities = activities
  },

  init(state, exceptId) {
    let savedActivityState = null

    if (exceptId) {
      savedActivityState =
        state.activities && !_.isEmpty(state.activities[exceptId])
          ? state.activities[exceptId]
          : null
    }

    state.activities = {}

    if (savedActivityState) {
      state.activities[exceptId] = savedActivityState
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
