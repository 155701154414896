import store from '@/store'

const defaultEndpoint = '/search'

const state = {
  users: {},
}

const getters = {
  users: (state) => {
    return state.users
  },
}

const actions = {
  async fetchUsers({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}/users`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
