import Vue from 'vue'
import VueRouter from 'vue-router'
import checkMiddleware from '../utils/routerHelper'
import store from '../store'

// Routes
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach((to, _, next) => {
  let middleware = []

  if (to.meta.middleware != null) {
    middleware = to.meta.middleware
  }

  return store.dispatch('auth/updateUser').then(() => {
    let response = checkMiddleware(middleware)

    if (store.getters['app/pageDidLoad']) {
      store.commit('app/TOGGLE_LOADING_ANIMATION', false)
      store.commit('app/TOGGLE_OVERLAY', false)
    }

    VueRouter.prototype.previousRoute = _

    if (!response.result) {
      let redirectRoute =
        response.redirect != null ? response.redirect : 'error-404'
      return next({ name: redirectRoute })
    }

    return next()
  })
})

router.afterEach((to, from) => {})

export default router
