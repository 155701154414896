import { mapGetters } from 'vuex'

export const userTour = {
  data() {
    return {
      tourInitiated: false,
      pageLoaded: false,
    }
  },

  beforeMount() {},
  mounted() {},

  methods: {
    startTour() {
      setTimeout(() => {
        if (
          this.pageLoaded &&
          !this.$tours.appTour.isRunning &&
          !window._.isEmpty(this.tourSteps)
        ) {
          this.$tours.appTour.start()
          this.tourInitiated = true
        }
      }, 1000)
    },

    isElementVisible(element) {
      const computedStyle = window.getComputedStyle(element)
      return (
        computedStyle.display !== 'none' &&
        computedStyle.visibility !== 'hidden'
      )
    },

    skipForDesktopBreakpoint() {
      return new Promise((resolve, reject) => {
        if (this.isDesktopBreakpoint) {
          this.skipStep()
        }

        return resolve()
      })
    },

    skipIfMissing(type, target) {
      return new Promise((resolve, reject) => {
        let element = document.querySelector(target)
        let targetExists = element && this.isElementVisible(element)

        if (!targetExists) {
          return this.skipStep()
        }

        return resolve()
      })
    },

    skipStep() {
      if (
        this.$tours.appTour.currentStep !==
        this.$tours.appTour.steps.length - 2
      ) {
        this.$tours.appTour.currentStep += 2
        return this.$tours.appTour.nextStep()
      }

      this.$tours.appTour.finish()
      return null
    },

    async refreshAppTour() {
      if (this.$tours.appTour && this.$tours.appTour.isRunning) {
        await this.$tours.appTour.stop()
      }

      if (this.tourEnabled) {
        this.startTour()
      }
    },
  },

  computed: {
    ...mapGetters('app', ['currentBreakPoint']),
    ...mapGetters('auth', ['user']),

    tourEnabled() {
      if (this.user.data == null || this.user.data.settings == null) {
        return false
      }

      return this.user.data.settings.enable_assistant
    },

    isDesktopBreakpoint() {
      return this.currentBreakPoint === 'lg' || this.currentBreakPoint === 'xl'
    },

    tourSteps: function () {
      let cookie = this.$cookies.get('agendum_tour_app')
      let steps = []

      if (cookie && cookie[this.routeName] != null) {
        return steps
      }

      if (this.routeName === 'home') {
        steps = [
          {
            target: '#app .bookmark-home',
            header: {
              title: 'Home Page',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'In the news feed section you can learn about new agendums that people share with you.',
          },
          {
            target: '#app .bookmark-agendums-timeline',
            header: {
              title: "Today's Agenda",
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content: 'Here you can see all your agendums.',
          },
          {
            target: '#app .bookmark-navigation',
            header: {
              title: 'Navigation tool',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'You can use this tool to easily navigate to any section within the app.',
          },
          {
            target: '#app .new-agendum-btn',
            header: {
              title: 'New Agendum',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              "Here you can quickly create an agendum. Don't worry, it will only be shared with your feed once you set its status to 'SET'. Also, its privacy setting is private by default.",
          },
        ]
      } else if (this.routeName === 'agendums-timeline') {
        steps = [
          {
            target: '#app .agendums-timeline-tab',
            header: {
              title: "Today's Agenda",
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'In this section you can see your agendums for today along with the ones in draft. You choose to filter by a day you select.',
          },
          {
            target: '#app .agendums-list-tab',
            header: {
              title: 'Upcoming & Past',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'In this section you can see all your agendums. Scroll down to see the upcoming ones and scroll up to see the ones that have ended. Use the filters to easily navigate through them.',
          },
        ]
      } else if (this.routeName === 'agendum-details') {
        steps = [
          // ___start - Agendum edit view
          {
            target: '#app .agendum-edit-details-tab',
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-edit-details-tab'),
            header: {
              title: 'Edit Details',
            },
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              "Here you can fill in all the details about the agendum. Don't worry, you don't have to fill in everything now. You can start with the information you know and decide everything else later when other members will join.",
          },
          {
            target: '#app .agendum-edit-settings-tab',
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-edit-settings-tab'),
            header: {
              title: 'Edit Settings',
            },
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'In this area you can change the agendum settings. Most of them will be reflected in the news feed. E.g.: You may choose to disclose the exact time of the agendum when people see your agendum in their news feed.',
          },
          {
            target: '#app .agendum-save-as-draft-btn',
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-save-as-draft-btn'),
            header: {
              title: 'Save draft',
            },
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              "If you don't have all the information set, you can save your agendum as draft for now. Note that your agendum will not be shared in the news feed and you can still invite other members and organize the agendum details together.",
          },
          {
            target: '#app .agendum-set-btn',
            before: (type) => this.skipIfMissing(type, '#app .agendum-set-btn'),
            header: {
              title: 'Save draft',
            },
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              "If you have all the information, you can set your agendum and let people know the details are already established. Note that your agendum will be automatically shared in the news feed. Don't worry the default privacy si 'private'. You can change that in the next step.",
          },
          //  ___end - Agendum edit view

          {
            target: '#app .agendum-details-tab',
            header: {
              title: 'Details',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Here you can see access all the information about this agendum. Based on your role, you may choose to make changes, invite people, add photos, polls and more (see agendum menu).',
          },
          {
            target: '#app .agendum-activity-tab',
            header: {
              title: 'Activity',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'In this tab you can see everything that has been changed or added to this agendum.',
          },
          {
            target: '#app .agendum-actions-menu',
            header: {
              title: 'Actions',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-actions-menu'),
            content:
              'Based on your role, you can find here all the agendum actions available for you.',
          },
          {
            target: '#app .agendum-status-badges',
            header: {
              title: "Agendum's Status",
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-members-see-all-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'The status of the agendum can be Draft, Set or Canceled. You may choose to set an agendum whenever you are sure about the details. If you are not sure where and when is going to happen, you can easily save it as draft and set it later when all the other members have confirmed the details.',
          },
          {
            target: '#app .agendum-members-see-all-btn',
            header: {
              title: 'Members',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-members-see-all-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'You can see here all other members, their confirmation status and their role in the agendum.',
          },
          {
            target: '#app .agendum-invite-members-btn',
            header: {
              title: 'Invite',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-invite-members-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Click this button to invite other people. Note that you can only invite users that are already part of your followers list.',
          },
          {
            target: '#app .agendum-sidebar-confirm-details-btn',
            header: {
              title: 'Confirm Details',
            },
            before: (type) =>
              this.skipIfMissing(
                type,
                '#app .agendum-sidebar-confirm-details-btn'
              ),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'This represents the status of your participation. The other members will receive a notification when you change your status.',
          },
          {
            target: '#app .agendum-sidebar-edit-membership-settings-btn',
            header: {
              title: 'My Settings',
            },
            before: (type) =>
              this.skipIfMissing(
                type,
                '#app .agendum-sidebar-edit-membership-settings-btn'
              ),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Here you can set your privacy settings for this agendum. All members have their own privacy settings and their own collections that they can assign to an agendum.',
          },
          {
            target: '#app .agendum-items-see-all-btn',
            header: {
              title: "Agendum's Items",
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-items-see-all-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              "Based on your role in the agendum, you can see here all the agendum's items and add more. An item of an agendum is one of the sub-activities you have in plan for your agendum. E.g.: Agendum: Visiting Rome | Item: Dinner at Il Ferro e il Fuoco.",
          },
          {
            target: '#app .agendum-spectators-see-all-btn',
            header: {
              title: 'Spectators',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .agendum-spectators-see-all-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Members that can no longer participate may choose to spectate instead of leaving the agendum. They will have restricted access and will receive less notifications, but they will still be able to see how the agendum unfolds.',
          },
        ]
      } else if (this.routeName === 'people') {
        steps = [
          {
            target: '#app .people-contacts-tab',
            header: {
              title: 'Contacts',
            },
            params: {
              enableScrolling: false,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'All Agendum users can follow anyone. Everybody can be both a public figure having lots of followers and a private person having as contacts only the people they actually know. When you share an agendum, you may choose to share it publicly with all your followers, or you may choose to share it with your lists of contacts.',
          },
          {
            target: '#app .people-add-contact-btn',
            header: {
              title: 'Add Contacts',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .people-add-contact-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Click Add Contact to add one of your followers as a contact. This way, you will be able to easily share your agendums with the people you want (friends / close friends / co-workers etc.)',
          },
        ]
      } else if (this.routeName === 'contact-lists') {
        steps = [
          {
            target: '#app .contact-lists-add-list-btn',
            header: {
              title: 'Add List',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .contact-lists-add-list-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'You can create a list inside another. It helps you organize your contacts easier and more efficient. E.g.: when you share an agendum with your close friends, only your close friends contacts will be able to see it. When you share an agendum with your friends list, the close friends will be able to see it as well. ',
          },
          {
            target: '#app .people-add-contact-btn',
            header: {
              title: 'Add Contacts',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .people-add-contact-btn'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Click Add Contact to add one of your followers as a contact.',
          },
        ]
      }

      if (
        process.env.VUE_APP_FEATURE_HOW_TO_WEB === 'true' &&
        (this.routeName === 'user-agendums' || this.routeName === 'agendums')
      ) {
        steps = [
          {
            target: '#app .app-user-dropdown-avatar',
            header: {
              title: 'Your Profile Section',
            },
            before: (type) =>
              this.skipIfMissing(type, '#app .app-user-dropdown-avatar'),
            params: {
              enableScrolling: true,
              stopOnFail: false,
              highlight: false,
            },
            content:
              'Access your profile here to change your profile picture or add more information.',
          },
          {
            target: '#app .feather-share-2',
            header: {
              title: 'Share Event',
            },
            before: (type) => this.skipIfMissing(type, '#app .feather-share-2'),
            params: {
              stopOnFail: false,
              highlight: true,
            },
            content:
              'Click this button to share this event on other social media platforms.',
          },
        ]
      }

      return steps
    },

    routeName() {
      return this.$route.name
    },

    routeQuery() {
      return this.$route.query
    },
  },

  watch: {
    async tourEnabled(to, from) {
      if (to) {
        this.pageLoaded = true
        this.startTour()
      } else if (this.$tours.appTour.isRunning) {
        await this.$tours.appTour.stop()
      }
    },
    async routeName(to, from) {
      await this.refreshAppTour()
    },
  },
}

export const _ = null
