import store from '@/store'

const defaultEndpoint = '/agendum-notes'

const state = {
  notes: {},
}

const getters = {
  notes: (state) => (agendumId) => {
    return state.notes[agendumId] ? state.notes[agendumId] : null
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchNotes({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setNotes', { data: null, agendumId: payload.agendum_id })
      return response
    }

    commit('setNotes', { data: response.data, agendumId: payload.agendum_id })

    return response
  },

  async createNote({ dispatch, commit }, form) {
    let { agendum_id } = form

    await form.post(defaultEndpoint, true)

    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setNote', {
        note: response.data,
        noteId: response.data.id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  async updateNote({ dispatch, commit }, form) {
    let { agendum_id, id } = form

    await form.post(`${defaultEndpoint}/${id}`, true)
    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setNote', {
        note: response.data,
        noteId: response.data.id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  async deleteNote({ dispatch, commit }, form) {
    let { agendum_id, id } = form

    await form.delete(`${defaultEndpoint}/${id}`)
    let response = form._response

    if (!form.errors.any() && response && response.status) {
      commit('setNote', {
        note: null,
        noteId: id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  checkNotesActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      if (event.action === 'created' || event.action === 'updated') {
        commit('setNote', {
          note: event.data,
          noteId: event.data.id,
          agendumId: event.data.agendum_id,
        })
      } else if (event.action === 'deleted') {
        commit('setNote', {
          note: null,
          noteId: event.data.id,
          agendumId: event.data.agendum_id,
        })
      }

      if (!_.isEmpty(event.activity)) {
        store.commit('agendumActivities/setActivity', {
          activity: !_.isEmpty(event.activity.attributes)
            ? event.activity
            : null,
          activityId: event.activity.id,
          agendumId: event.data.agendum_id,
        })
      }
    }
  },
}

const mutations = {
  setNotes(state, payload) {
    let { data, agendumId } = payload

    let newData = {
      data: !_.isEmpty(data) && data.data ? data.data : null,
      nextPageLink: !_.isEmpty(data) && data.links ? data.links.next : null,
    }

    let notes = { ...state.notes }
    notes[agendumId] = newData
    state.notes = notes
  },

  setNote(state, data) {
    let keyToUpdate = null
    let { noteId, note, agendumId } = data

    let notes = { ...state.notes }

    if (_.isEmpty(notes[agendumId]) || _.isEmpty(notes[agendumId].data)) {
      if (note == null) return

      notes[agendumId] = {
        data: [note],
        nextPageLink: null,
      }

      state.notes = notes
      return
    }

    for (const key in notes[agendumId].data) {
      if (
        notes[agendumId].data[key] &&
        notes[agendumId].data[key].id === noteId
      ) {
        keyToUpdate = key
        break
      }
    }

    if (note != null) {
      if (
        keyToUpdate == null &&
        notes[agendumId].data[0].attributes.created_at_timestamp <=
          note.attributes.created_at_timestamp
      ) {
        notes[agendumId].data = [note, ...notes[agendumId].data]
      } else if (keyToUpdate != null) {
        notes[agendumId].data[keyToUpdate].attributes = {
          ...note.attributes,
        }
      }
    } else if (note == null && keyToUpdate != null) {
      notes[agendumId].data.splice(keyToUpdate, 1)
    }

    state.notes = notes
  },

  init(state, exceptId) {
    let savedNoteState = null

    if (exceptId) {
      savedNoteState =
        state.notes && !_.isEmpty(state.notes[exceptId])
          ? state.notes[exceptId]
          : null
    }

    state.notes = {}

    if (savedNoteState) {
      state.notes[exceptId] = savedNoteState
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
