const defaultEndpoint = '/requests'

const state = {}

const getters = {}

const actions = {
  actionUpdateAgendumRequest({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}`)
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
