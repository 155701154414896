import store from '@/store'

export const mediaPreview = {
  /* eslint-disable no-param-reassign */
  inserted: (el) => {
    function extractMediaUrls() {
      let photos = []
      let mediaSrc = el.getAttribute('media-src')

      if (el instanceof HTMLImageElement) {
        let url = handleImage(el, mediaSrc)
        photos.push({ url })
        return photos
      }

      let images = el.getElementsByTagName('img')
      if (!images.length) {
        console.warn('[media-preview] Could not find any media url to preview.')
        return
      }

      for (let i in images) {
        if (images[i] instanceof HTMLImageElement) {
          let url = handleImage(images[i], mediaSrc)
          photos.push({ url })
        }
      }

      return photos
    }

    function handleImage(image, mediaSrc = null) {
      if (image.hasAttribute('media-src')) {
        mediaSrc = image.getAttribute('media-src')
      } else if (mediaSrc == null) {
        mediaSrc = image.getAttribute('src')
      }

      return mediaSrc
    }

    el.addEventListener('click', (event) => {
      let photos = extractMediaUrls()
      store.commit('app/LOAD_PHOTOS_TO_PREVIEW', photos)
    })
  },
  unbind(el) {},
  /* eslint-enable no-param-reassign */
}
