const defaultEndpoint = '/profile'

const state = {
  profileData: null,
  settings: null,
  completeProfile: null,
}

const getters = {
  profileData: (state) => state.profileData,
  settings: (state) => state.settings,
  completeProfile: (state) => state.completeProfile,
}

const actions = {
  fetchProfileData({ commit }, data) {
    let { payload } = data

    return this._vm.$api
      .get(`${defaultEndpoint}`, { params: payload })
      .then((response) => {
        commit('setProfileData', response.data)
        return response
      })
      .catch((error) => {
        commit('setProfileData', null)
      })
  },

  async fetchFeed({ commit }, data) {
    let { payload, username } = data
    let endpoint = `${defaultEndpoint}/feed`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async selectCoverTemplate({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/cover-template`)
  },
}

const mutations = {
  setProfileData(state, response) {
    if (!_.isEmpty(response.data.attributes)) {
      state.profileData = { ...response.data.attributes }
    }

    if (!_.isEmpty(response.data.complete_profile)) {
      state.completeProfile = { ...response.data.complete_profile }
    }

    if (!_.isEmpty(response.data.relations.settings)) {
      state.settings = { ...response.data.relations.settings }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
