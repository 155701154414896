import store from '@/store'

const defaultEndpoint = '/agendum-messages'

const state = {
  messages: {},

  forceRecomputeCounter: 0,
}

const getters = {
  messages: (state) => (agendumId) => {
    return state.messages[agendumId] ? state.messages[agendumId] : null
  },

  forceRecomputeCounter: (state) => {
    return state.forceRecomputeCounter
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchMessages({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setMessages', { data: null, agendumId: payload.agendum_id })
      return response
    }

    response.data.data = response.data.data.reverse()

    commit('setMessages', {
      data: response.data,
      agendumId: payload.agendum_id,
    })

    return response
  },

  async createMessage({ dispatch, commit }, form) {
    let { agendum_id } = form

    let placeholderMessageId = form.placeholder_message_id
      ? form.placeholder_message_id
      : null

    await form.post(defaultEndpoint, true)

    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setMessage', {
        message: response.data,
        messageId: response.data.id,
        agendumId: agendum_id,
        placeholderMessageId: placeholderMessageId,
      })
      store.commit('conversations/updateConversationMessageInList', {
        id: agendum_id,
        message: response.data,
      })
    }

    return response
  },

  async updateMessage({ dispatch, commit }, form) {
    let { agendum_id, id } = form

    await form.post(`${defaultEndpoint}/${id}`, true)
    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setMessage', {
        message: response.data,
        messageId: response.data.id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  async deleteMessage({ dispatch, commit }, form) {
    let { agendum_id, id } = form

    await form.delete(`${defaultEndpoint}/${id}`)
    let response = form._response

    if (!form.errors.any() && response && response.status) {
      commit('setMessage', {
        message: null,
        messageId: id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  checkMessagesActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      if (
        event.causerId !== store.getters['auth/user'].id ||
        event.data.attributes.type === 'system'
      ) {
        if (event.action === 'created' || event.action === 'updated') {
          commit('setMessage', {
            message: event.data,
            messageId: event.data.id,
            agendumId: event.data.agendum_id,
          })
          store.commit('agendums/setAgendumNewMessages', {
            id: event.data.agendum_id,
          })
          store.commit('conversations/updateConversationMessageInList', {
            id: event.data.agendum_id,
            message: event.data,
          })

          commit('incrementForceRecomputeCounter')
        } else if (event.action === 'deleted') {
          commit('setMessage', {
            message: null,
            messageId: event.data.id,
            agendumId: event.data.agendum_id,
          })
          store.commit('conversations/updateConversationMessageInList', {
            id: event.data.agendum_id,
            message: null,
          })

          commit('incrementForceRecomputeCounter')
        }
      }

      if (!_.isEmpty(event.activity)) {
        store.commit('agendumActivities/setActivity', {
          activity: !_.isEmpty(event.activity.attributes)
            ? event.activity
            : null,
          activityId: event.activity.id,
          agendumId: event.data.agendum_id,
        })
      }
    }
  },
}

const mutations = {
  setMessages(state, payload) {
    let { data, agendumId } = payload

    let newData = {
      data: !_.isEmpty(data) && data.data ? data.data : [],
      nextPageLink: !_.isEmpty(data) && data.links ? data.links.next : null,
    }

    let messages = { ...state.messages }

    if (agendumId) {
      messages[agendumId] = newData
    } else {
      messages = {}
    }
    state.messages = messages
  },

  setMessage(state, data) {
    let keyToUpdate = null
    let { messageId, message, agendumId, placeholderMessageId } = data

    let messages = { ...state.messages }

    if (_.isEmpty(messages[agendumId]) || _.isEmpty(messages[agendumId].data)) {
      if (message == null) return

      messages[agendumId] = {
        data: [message],
        nextPageLink: null,
      }

      state.messages = messages
      return
    }

    for (const key in messages[agendumId].data) {
      let keyToSearchFor = messageId

      if (placeholderMessageId) {
        keyToSearchFor = placeholderMessageId
      }

      if (
        messages[agendumId].data[key] &&
        messages[agendumId].data[key].id === keyToSearchFor
      ) {
        keyToUpdate = key
        break
      }
    }

    if (message != null) {
      if (
        keyToUpdate == null &&
        placeholderMessageId == null &&
        messages[agendumId].data[0].attributes.created_at_timestamp <=
          message.attributes.created_at_timestamp
      ) {
        messages[agendumId].data = [...messages[agendumId].data, message]
      } else if (keyToUpdate != null) {
        if (placeholderMessageId) {
          messages[agendumId].data[keyToUpdate] = { ...message }
        } else {
          messages[agendumId].data[keyToUpdate].attributes = {
            ...message.attributes,
          }
        }
      }
    } else if (message == null && keyToUpdate != null) {
      messages[agendumId].data.splice(keyToUpdate, 1)
    }

    state.messages = messages
  },

  init(state, exceptId) {
    let savedMessageState = null

    if (exceptId) {
      savedMessageState =
        state.messages && !_.isEmpty(state.messages[exceptId])
          ? state.messages[exceptId]
          : null
    }

    state.messages = {}

    if (savedMessageState) {
      state.messages[exceptId] = savedMessageState
    }
  },

  incrementForceRecomputeCounter(state, value = 1) {
    state.forceRecomputeCounter += value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
