import _ from 'lodash'
import Errors from './Errors'

class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   * @param api
   * @param store
   * @param resetAfterSubmit
   */
  constructor(data, api, store = null, resetAfterSubmit = true) {
    this.originalData = _.cloneDeep(data)
    this.$api = api

    if (store) {
      this.$store = store
    }

    for (let field in data) {
      this[field] = data[field]
    }

    this.errors = new Errors()
    this.loading = false
    this.successful = false
    this.resetAfterSubmit = resetAfterSubmit
    this.statusMessage = ''
  }

  /**
   * Fetch all relevant data for the form.
   */
  data() {
    let data = {}

    for (let property in this.originalData) {
      data[property] = this[property]
    }

    return data
  }

  record(data) {
    for (let field in data) {
      this[field] = data[field]
    }
  }

  /**
   * Reset the form fields.
   */
  reset(clearErrors = true) {
    for (let field in this.originalData) {
      this[field] = _.cloneDeep(this.originalData[field])
    }

    if (clearErrors) {
      this.errors.clear()
    }
  }

  startProcessing() {
    this.loading = true
    this.successful = false

    if (this.$store) {
      this.$store.commit('app/TOGGLE_OVERLAY', true)
      this.$store.commit('app/TOGGLE_LOADING_ANIMATION', true)
    }
  }

  finishProcessing(status = true) {
    this.loading = false
    this.successful = status

    if (this.$store) {
      this.$store.commit('app/TOGGLE_OVERLAY', false)
      this.$store.commit('app/TOGGLE_LOADING_ANIMATION', false)
    }
  }

  /**
   * Send a POST request to the given URL.
   * .
   * @param {string} url
   */
  post(url, withFiles = false) {
    return this.submit('post', url, withFiles)
  }

  /**
   * Send a PUT request to the given URL.
   * .
   * @param {string} url
   */
  put(url, withFiles = false) {
    return this.submit('put', url, withFiles)
  }

  /**
   * Send a PATCH request to the given URL.
   * .
   * @param {string} url
   */
  patch(url, withFiles = false) {
    return this.submit('patch', url, withFiles)
  }

  /**
   * Send a DELETE request to the given URL.
   * .
   * @param {string} url
   */
  delete(url) {
    return this.submit('delete', url)
  }

  /**
   * Submit the form.
   *
   * @param {string} requestType
   * @param {string} url
   * @param withFiles
   */
  submit(requestType, url, withFiles = false) {
    this.startProcessing()

    let config = null
    let data = this.data()

    if (withFiles) {
      config = {
        header: {
          'Content-Type': 'multipart/form-data',
        },
      }

      let formData = new FormData()

      for (let property in data) {
        if (data[property] != null) {
          formData.append(property, data[property])
        }
      }

      data = formData
    }

    if (requestType === 'delete') {
      data = { params: data }
    }

    return this.$api[requestType](url, data, config)
      .then((response) => {
        if (
          response.data == null ||
          (response.data.hasOwnProperty('status') &&
            (response.data.status === 'failed' ||
              response.data.status === 'error'))
        ) {
          let errors = {}
          let message = 'Something went wrong. Please try again later.'

          if (
            response.data &&
            response.data.hasOwnProperty('readable_message')
          ) {
            message = response.data.readable_message
          }

          this.onFail(errors, message)
          return false
        }

        this.onSuccess(response.data)
      })
      .catch((error) => {
        let errors = {}
        let message = 'Something went wrong. Please try again later.'

        if (_.isEmpty(error.response)) {
          errors = {}
        } else if (error.response.data.hasOwnProperty('errors')) {
          errors = error.response.data.errors
        } else {
          errors = error.response.data
        }

        if (errors.hasOwnProperty('readable_message')) {
          message = Array.isArray(errors.readable_message)
            ? errors.readable_message[0]
            : errors.readable_message
        }

        this.onFail(errors, message)
      })
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   */
  onSuccess(data) {
    if (this.resetAfterSubmit) {
      this.reset()
    }
    this._response = data
    this.errors.clear()
    this.finishProcessing()
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} errors
   * @param message
   */
  onFail(errors, message = true) {
    if (_.isEmpty(errors) || this.deepCheckOriginalData(errors)) {
      errors.internalError = [message]
    }

    this.errors.record(errors)
    this.finishProcessing(false)
  }

  /*
   * Set the validation errors
   */
  setErrors(errors) {
    this.loading = false

    this.errors.set(errors)
  }

  deepCheckOriginalData(errors) {
    let flattedData = this.recursivelyBuildFlattenData('', this.originalData)

    return _.difference(_.keys(errors), _.values(flattedData)).length !== 0
  }

  recursivelyBuildFlattenData(key, value) {
    let flattedData = []

    if (key !== '') {
      flattedData.push(key)
    }

    if (
      typeof value === 'object' &&
      value !== null &&
      Object.keys(value).length
    ) {
      for (let field in value) {
        let newKey = key !== '' ? `${key}.${field}` : field
        let array = this.recursivelyBuildFlattenData(newKey, value[field])
        flattedData = flattedData.concat(array)
      }
    }

    return flattedData
  }
}

export default Form
