import store from '../store'

export default function checkMiddleware(items = [], count = 0) {
  let check = {
    result: true,
    redirect: null,
  }

  let item = items[count]

  if (item == null) return check

  if (
    process.env.NODE_ENV === 'production' &&
    store.getters['app/networkError']
  ) {
    return {
      result: false,
      redirect: 'no-internet-connection',
    }
  }

  switch (item) {
    case 'guest':
      check = checkGuestMiddleware()
      break

    case 'auth':
      check = checkAuthMiddleware()
      break
    case 'verified':
      check = checkVerifiedMiddleware()
      break
    case 'unverified':
      check = checkUnverifiedMiddleware()
      break
    default:
      check.result = false
      check.redirect = 'error-404'
  }

  if (check.result && items[++count] != null) {
    return checkMiddleware(items, count)
  }

  return check
}

function checkGuestMiddleware() {
  let loggedIn = store.getters['auth/isLoggedIn']
  let redirect = null

  if (loggedIn) {
    redirect = 'error-404'
  }

  if (process.env.VUE_APP_FEATURE_COMING_SOON_LANDING_PAGE === 'true') {
    return {
      result: false,
      redirect: 'coming-soon',
    }
  }

  return {
    result: !loggedIn,
    redirect: redirect,
  }
}

function checkAuthMiddleware() {
  let loggedIn = store.getters['auth/isLoggedIn']
  let blocked = store.getters['auth/blocked']
  let redirect = null

  if (!loggedIn) {
    // attempt to fetch user data
    redirect = 'welcome'
  }

  if (blocked) {
    return {
      result: false,
      redirect: 'coming-soon',
    }
  }

  store.commit('appReviews/incrementPageCounter')

  return {
    result: loggedIn,
    redirect: redirect,
  }
}

function checkVerifiedMiddleware() {
  let loggedIn = store.getters['auth/isLoggedIn']
  let verified = store.getters['auth/user'].verified
  let redirect = null

  if (!loggedIn) {
    redirect = 'welcome'
  }

  if (!verified) {
    redirect = 'verify-email'
  }

  return {
    result: loggedIn && verified,
    redirect: redirect,
  }
}

function checkUnverifiedMiddleware() {
  let loggedIn = store.getters['auth/isLoggedIn']
  let verified = store.getters['auth/user'].verified
  let redirect = null

  if (!loggedIn) {
    redirect = 'welcome'
  }

  if (verified) {
    redirect = 'home'
  }

  return {
    result: loggedIn && !verified,
    redirect: redirect,
  }
}

export function attemptToFetchUser() {
  return store.dispatch('auth/updateUser').then(() => {
    return store.getters['auth/isLoggedIn']
  })
}
