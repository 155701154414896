import axios from 'axios'
import store from '@/store'
import cookies from 'axios/lib/helpers/cookies'

const title = 'Share and organize your personal calendar with Agendum.';
const description = 'Register or Sign In to Agendum - Let people know what\'s next in your calendar, keep track of the places you visited, build your public and private profile with a single account.';
const image = 'https://www.agendum.club/logo_cover.png';
const url = 'https://www.agendum.club';

/**
 * @return Array
 */
let MetaTags = (data = {}) => {

  return [
    { vmid: 'description', name: 'description', content: data['description'] ? data['description'] : description },
    {
      vmid: 'og:apple-mobile-web-app-title',
      property: 'og:apple-mobile-web-app-title',
      content: data['articleTitle'] ? data['articleTitle'] : title,
    },
    {
      vmid: 'og:title',
      property: 'og:title',
      content: data['articleTitle'] ? data['articleTitle'] : title,
    },
    {
      vmid: 'og:description',
      property: 'og:description',
      content: data['articleDescription'] ? data['articleDescription'] : description
    },
    {
      vmid: 'og:image',
      property: 'og:image',
      content: data['articleImage'] ? data['articleImage'] : image,
    },
    {
      vmid: 'og:url',
      property: 'og:url',
      content: data['articleUrl'] ? data['articleUrl'] : url,
    },
    {
      vmid: 'twitter:title',
      property: 'twitter:title',
      content: data['articleTitle'] ? data['articleTitle'] : title,
    },
    {
      vmid: 'twitter:description',
      property: 'twitter:description',
      content: data['articleTitle'] ? data['articleTitle'] : title
    },
  ]
}

const AppMetaTags = {
  install(Vue, options) {
    Vue.prototype.$appMetaTags = MetaTags
  },
}

export default AppMetaTags
