import store from '@/store'

const defaultEndpoint = '/app-reviews'

const state = {
  pageCounter: 0,
}

const getters = {
  pageCounter: (state) => {
    return state.pageCounter
  },
}

const actions = {
  async createReview({ dispatch, commit }, form) {
    return form.post(defaultEndpoint, true)
  },

}

const mutations = {
  setPageCounter(state, value) {
    state.pageCounter = value
  },

  incrementPageCounter(state) {
    state.pageCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
