import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const formToastification = {
  components: {
    ToastificationContent,
  },

  data() {
    return {
      toastPosition: 'top-right',
    }
  },

  methods: {
    showToast(variant, text = '', timeout = 3000) {
      let position = this.toastPosition

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: text,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      )
    },

    formSubmitted(success = true, message = null, timeout = 4000) {
      if (success) {
        let successMessage = message ? message : 'Information Updated.'
        this.showToast('success', successMessage)
      } else {
        let errorMessage = message
          ? message
          : 'Something went wrong. Please try again later.'
        this.showToast('danger', errorMessage, timeout)
      }
    },
  },

  watch: {},
}

export const _ = null
