export const pageLoadingState = {
  data() {
    return {}
  },

  methods: {
    setPageDidLoad(value = true) {
      this.$store.commit('app/SET_PAGE_DID_LOAD', value)
    },
  },

  computed: {
    pageDidLoad() {
      return this.$store.getters['app/pageDidLoad']
    },
  },
}

export const _ = null
