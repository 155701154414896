import store from '@/store'
import router from '@/router'
import { commit } from 'lodash/seq'

const defaultEndpoint = '/conversations'

const state = {
  conversationList: [],
  filterSelectedDate: null,
  conversationNextPageLink: null,
  conversationFiltersApplied: null,
  conversationListScrollIndex: {},
  shallFetch: false,

  shallRefreshCounter: 0,
  newMessagesCount: 0,

  forceRecomputeCounter: 0,

  conversations: {},
  conversationFilters: {},
  conversation: null,

  memberships: {},
  membership: null,
}

const getters = {
  shallFetch: (state) => {
    return state.shallFetch
  },

  shallRefreshCounter: (state) => {
    return state.shallRefreshCounter
  },

  newMessagesCount: (state) => {
    return state.newMessagesCount
  },

  conversationListScrollIndex: (state) => {
    return state.conversationListScrollIndex
  },

  forceRecomputeCounter: (state) => {
    return state.forceRecomputeCounter
  },

  conversationList: (state) => {
    return state.conversationList ? state.conversationList : null
  },
  conversationNextPageLink: (state) => {
    return state.conversationNextPageLink
      ? state.conversationNextPageLink
      : null
  },

  conversationFiltersApplied: (state) => {
    return state.conversationFiltersApplied
      ? state.conversationFiltersApplied
      : null
  },

  conversationFilters: (state) => {
    return state.conversationFilters ? state.conversationFilters : null
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchConversations({ commit }, data) {
    let { payload, username, id, incrementForceRecomputeCounter } = data
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (incrementForceRecomputeCounter) {
      commit('incrementForceRecomputeCounter')
    }

    return response
  },

  createConversation({ dispatch, commit }, form) {
    return form.post(defaultEndpoint)
  },

  updateConversation({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}`)
  },

  updateSettings({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/settings`)
  },

  addConversationMembers({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/add-members`)
  },

  async deleteConversation({ dispatch, commit }, form) {
    let { id } = form
    await form.delete(`${defaultEndpoint}/${id}`)

    return form._response
  },

  readMessages({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/read`)
  },

  incrementNewMessagesCount({ commit }) {
    commit('setNewMessagesCount', state.newMessagesCount + 1)
  },

  resetNewMessagesCount({ commit }) {
    commit('setNewMessagesCount', 0)
  },

  updateNewMessagesCount({ commit }, count) {
    commit('setNewMessagesCount', count)
  },

  checkConversationsActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      if (event.action === 'created' || event.action === 'updated') {
        commit('setConversationInList', {
          data: event.data,
          id: event.data.id,
        })

        commit('incrementForceRecomputeCounter')
      }
    }
  },
}

const mutations = {
  setConversationList(state, data) {
    state.conversationList = !_.isEmpty(data) ? [...data] : null

    if (data == null) {
      state.conversationNextPageLink = null
      state.conversationFiltersApplied = null
    }
  },

  setConversationInList(state, payload) {
    let { data, id } = payload
    let conversation = null

    if (_.isEmpty(state.conversationList)) {
      return null
    }

    let conversations = [...state.conversationList]

    if (data) {
      let replacedItem = null
      conversations = [
        ...conversations.filter((item) => {
          if (item.id === id) {
            item.attributes = { ...data.attributes }
            item.relations.last_message = { ...data.relations.last_message }
            item.relations.stats.new_messages++
            replacedItem = { ...item }
          }

          return item.id !== id
        }),
      ]

      if (!replacedItem) {
        replacedItem = data
      }

      state.conversationList = [replacedItem, ...conversations]
    } else {
      state.conversationList = [
        ...conversations.filter((item) => item.id !== id),
      ]
    }
  },

  updateConversationMessagesCountInList(state, payload) {
    let { id, value, incrementWithValue } = payload
    let conversation = null

    if (_.isEmpty(state.conversationList)) {
      return null
    }

    let conversations = [...state.conversationList]
    let replacedItem = null

    conversations = [
      ...conversations.filter((item) => {
        if (item.id === id) {
          if (value != null) {
            item.relations.stats.new_messages = value
          } else {
            let newCount = item.relations.stats.new_messages + value
            item.relations.stats.new_messages = newCount > 0 ? newCount : 0
          }
          replacedItem = { ...item }
        }
        return item.id !== id
      }),
    ]

    if (replacedItem) {
      state.conversationList = [replacedItem, ...conversations]
    }
  },

  updateConversationMessageInList(state, payload) {
    let { id, message } = payload
    let conversation = null

    if (_.isEmpty(state.conversationList)) {
      return null
    }

    let conversations = [...state.conversationList]
    let replacedItem = null

    conversations = [
      ...conversations.filter((item) => {
        if (item.id === id) {
          item.relations.last_message = { ...message }
          replacedItem = { ...item }
        }

        return item.id !== id
      }),
    ]

    if (replacedItem) {
      state.conversationList = [replacedItem, ...conversations]
    }
  },

  setFilterSelectedDate(state, data) {
    state.filterSelectedDate = data
  },

  setConversationListScrollIndex(state, index) {
    state.conversationListScrollIndex = index
  },

  setConversationNextPageLink(state, data) {
    state.conversationNextPageLink = data
  },

  setNewMessagesCount(state, value = 0) {
    state.newMessagesCount = value
  },

  incrementForceRecomputeCounter(state, value = 1) {
    state.forceRecomputeCounter += value
  },

  incrementNewMessagesCount(state, value = 1) {
    let newCount = state.newMessagesCount + value
    state.newMessagesCount = newCount > 0 ? newCount : 0
  },

  setConversationFiltersApplied(state, data) {
    state.conversationFiltersApplied = data
  },

  setConversationFilters(state, data) {
    state.conversationFilters = data
  },

  setShallFetch(state, value) {
    state.shallFetch = value
  },

  init(state, exceptId) {
    let savedConversationState = null
    let savedMembershipState = null

    if (exceptId) {
      savedConversationState =
        state.conversations && !_.isEmpty(state.conversations[exceptId])
          ? state.conversations[exceptId]
          : null
      savedMembershipState =
        state.memberships && !_.isEmpty(state.memberships[exceptId])
          ? state.memberships[exceptId]
          : null
    }
    state.conversations = {}
    state.memberships = {}
    state.conversation = null
    state.membership = null

    if (savedConversationState) {
      state.conversations[exceptId] = savedConversationState
    }

    if (savedMembershipState) {
      state.memberships[exceptId] = savedMembershipState
    }
  },

  incrementShallRefreshCounter(state) {
    state.shallRefreshCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
