<!-- PWAPrompt.vue -->
<template>
  <b-toast
      id="install-pwa-toast"
      variant="primary"
      title="Install Agendum App?"
      noAutoHide
      solid
      toaster="b-toaster-bottom-full"
      :visible="shown"
  >
    <div class="w-100 d-flex justify-content-center align-items-center">
      <b-button
          class="mr-1"
          variant="primary"
          type="button"
          size="sm"
          :disabled="loading"
          @click="installPWA"
      >
        Install
      </b-button>

      <b-button
          variant="outline-primary"
          type="button"
          size="sm"
          :disabled="loading"
          @click="dismissPrompt"
      >
        No, thanks
      </b-button>
    </div>
  </b-toast>
</template>

<script>
import {BButton, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {formToastification} from '@core/mixins/ui/formToastification'
import {handleWebview} from "@core/mixins/ui/handleWebview";
import {mapGetters} from "vuex";

export default {
  name: 'InstallPWA',

  components: {
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [formToastification, handleWebview],

  data: () => ({
    shown: false,
    loading: false,
    isAppInstalled: false,
    toastPosition: 'bottom-right',
    logo: require('@/assets/images/logo/logo.svg'),
  }),

  beforeMount() {
    if (
        !this.userDeclinedInstalling &&
            this.isDesktopBreakpoint
    ) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installEvent = e
        this.shown = true
      })
    }


  },

  methods: {
    dismissPrompt() {
      this.shown = false
      this.declineInstalling()
    },

    declineInstalling() {
      this.$cookies.set('agendum_app', {
        install: {
          consent: false,
        },
      })
    },

    acceptInstalling() {
      this.$cookies.set('agendum_app', {
        install: {
          consent: true,
        },
      })
    },

    installPWA() {
      this.loading = true
      this.installEvent.prompt()
      this.installEvent.userChoice
          .then((choice) => {
            this.dismissPrompt() // Hide the prompt once the user's clicked
            if (choice.outcome === 'accepted') {
              // Do something additional if the user chose to install
              this.acceptInstalling()
            } else {
              // Do something additional if the user declined
              this.declineInstalling()
            }
          })
          .finally(() => (this.loading = false))
    },

    makeToast() {
      this.$bvToast.show('install-pwa-toast')
    },
  },

  computed: {
    ...mapGetters('app', ['currentBreakPoint']),

    isMobileBreakPoint() {
      return this.currentBreakPoint === 'sm' || this.currentBreakPoint === 'xs'
    },

    isDesktopBreakpoint() {
      return this.currentBreakPoint === 'lg' || this.currentBreakPoint === 'xl'
    },

    userDeclinedInstalling() {
      if (_.isEmpty(this.cookieApp)) {
        return false
      }

      return !this.cookieApp.install.consent
    },

    cookieApp() {
      return this.$cookies.get('agendum_app')
    },
  },

  watch: {},
}
</script>

<style scoped></style>
