import store from '@/store';

const defaultEndpoint = '/contacts'

const state = {
  contactLists: [],
  flatListTree: [],
  contactSets: [],
  fetchedContactSets: [],
  currentContactSet: {},

  forceRecompute: 0,
}

const getters = {
  contactLists: (state) => data => {
    return state.contactLists
  },

  flatListTree: (state) => {
    return state.flatListTree
  },

  listDetails: (state) => listId => {
    if (!state.flatListTree[listId]) {
      return null
    }

    return state.flatListTree[listId]
  },

  contactSet: (state) => listId => {
    if (!state.fetchedContactSets.includes(listId)) {
      return null
    }

    return state.contactSets[listId]
  },

  currentContactSet: (state) => {
    return state.currentContactSet
  },
}

const actions = {
  async fetchContacts({commit}, data = {}) {
    let {payload, username} = data
    let endpoint = `${defaultEndpoint}`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    let list_id = payload ? payload.list_id : 'contacts'
    let search = payload ? payload.search : null
    let response = await this._vm.$api.get(endpoint, { params: payload }).catch((error) => {
      return {data: null}
    })

    if (response.data == null || response.data.data == null) {
      if (search == null) {
        commit('unsetContactSet', list_id)
      }
      return response
    }

    response.data.list_id = list_id
    if (search == null) {
      commit('setContactSet', response.data)
      commit('setFetchedContactSet', list_id)
    }

    return response
  },


  async fetchLists({commit}, payload) {
    let endpoint = `${defaultEndpoint}/lists`

    let response = await this._vm.$api.get(endpoint, { params: payload }).catch((error) => {
      return {data: null}
    })

    if (response.data == null || response.data.data == null) {
      commit('setContactLists', null)
      commit('buildFlatListTree', null)
      return response
    }

    commit('setContactLists', response.data.data)
    commit('buildFlatListTree', response.data.data)
    return response
  },

  async addList({dispatch, commit}, form) {
    await form.post(`${defaultEndpoint}/lists`)
    let response = form._response

    if (!form.errors.any() && response) {
      commit('setContactLists', response.data)
      commit('buildFlatListTree', response.data)
    }

    return response
  },

  async editList({dispatch, commit}, form) {
    let listId = form.list_id
    let newParentId = form.parent_list_id ? form.parent_list_id : null
    let hasBeenMoved = !_.isEmpty(form.parent_list_id)
    await form.post(`${defaultEndpoint}/lists/${form.list_id}`)
    let response = form._response

    if (!form.errors.any() && response) {
      if (hasBeenMoved) {
        let listDetails = state.flatListTree[listId]
        commit('unsetContactSet', listDetails.parentId)

        commit('setContactLists', response.data)
        commit('buildFlatListTree', response.data)

        commit('unsetContactSet', newParentId)
      } else {
        commit('setContactLists', response.data)
        commit('buildFlatListTree', response.data)
      }

    }

    return response
  },

  async deleteList({dispatch, commit}, form) {
    let payload = {}
    let listId = form.list_id

    if (form.remove_sub_lists) {
      payload.remove_sub_lists = form.remove_sub_lists
    }

    await form.delete(`${defaultEndpoint}/lists/${form.list_id}`, { params: payload })
    let response = form._response

    if (!form.errors.any()) {
      let listDetails = state.flatListTree[listId]
      commit('unsetContactSet', listDetails.parentId)
      commit('setContactLists', response.data)
      commit('buildFlatListTree', response.data)
    }

    return response
  },

  async setContact({dispatch, commit}, form) {
    let contactLists = form.contact_lists ? form.contact_lists : []
    let username = form.username
    let response = await form.post(`${defaultEndpoint}`)

    if (!form.errors.any()) {
      if (contactLists) {
        contactLists.forEach((item) => {
          commit('unsetContactSet', item)
        })
        store.dispatch('notifications/analyseShallRefresh', username)
      } else {
        commit('unsetAllContactSets')
      }
    }

    return response
  },

  async deleteContact({dispatch, commit}, form) {
    let username = form.username
    let endpoint = `${defaultEndpoint}/${username}`
    let payload = {}

    if (form.list_id) {
      payload.list_id = form.list_id
    }

    let response = await this._vm.$api.delete(endpoint, { params: payload })

    if (response.data == null) {
      return response
    }

    store.dispatch('notifications/analyseShallRefresh', username)

    if (form.list_id) {
      commit('unsetContactSet', form.list_id)
    } else {
      commit('unsetAllContactSets')
    }
  },
}

var bfs = function(tree, key, collection) {
  if (!tree[key] || tree[key].length === 0) return
  for (var i=0; i < tree[key].length; i++) {
    var child = tree[key][i]
    child.parentId = tree.id
    collection[child.id] = child
    bfs(child, key, collection)
  }
  return
}

const createDataTree = dataset => {
  const hashTable = Object.create(null)
  dataset.forEach(aData => hashTable[aData.id] = {...aData, children: []})
  const dataTree = []
  dataset.forEach(aData => {
    if(aData.parentId) hashTable[aData.parentId].children.push(hashTable[aData.id])
    else dataTree.push(hashTable[aData.id])
  })
  return dataTree
};

const mutations = {
  setContactSet (state, data = null) {
    let newData = {
      data: data && data.data ? data.data : null,
      nextPageLink: data && data.links ? data.links.next : null
    }

    if (data && data.list_id) {
      state.contactSets[data.list_id] = newData
    } else {
      state.currentContactSet = newData
    }
  },

  setContactLists (state, data = []) {
    state.contactLists = data
  },

  setFlatListTree (state, data = []) {
    state.flatListTree = data
  },

  buildFlatListTree (state, data = []) {
    let flatListTree = []

    if (!_.isEmpty(data)) {
      let rootNode = data[0]
      rootNode.parentId = null
      flatListTree[rootNode.id] = rootNode
      bfs(rootNode, "children", flatListTree)
    }

    state.flatListTree = flatListTree
  },

  setFetchedContactSet(state, listId) {
    state.fetchedContactSets.push(listId)
  },

  unsetContactSet(state, listId, unsetAncestors = true) {
    const index = state.fetchedContactSets.indexOf(listId)
    if (index > -1) {
      state.fetchedContactSets.splice(index, 1)
    }

    if (state.contactSets[listId]) {
      delete (state.contactSets[listId])
    }

    if (state.flatListTree[listId]) {
      let list = state.flatListTree[listId]
      if (list.parentId) {
        mutations.unsetContactSet(state, list.parentId)
      }
    }
  },

  unsetAllContactSets(state) {
    state.fetchedContactSets = []
    state.contactSets = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
