import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store'
import router from '@/router'

Vue.component(FeatherIcon.name, FeatherIcon)

window.redirectToProfileRoute = function redirectToProfileRoute(userId) {
  let username = store.getters['auth/user'].id

  let route = {
    name: userId === username ? 'profile' : 'user-profile',
  }

  if (userId !== username) {
    route.params = { username }
  }

  router.push(route)
}
