import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    shallShowLoadingAnimation: false,
    shallShowLoadingLayout: false,
    shallShowNetworkError: false,
    pageDidLoad: false,
    photosToPreview: null,
    networkError: null,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state

      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'

      return 'xs'
    },

    windowWidth: (state) => state.windowWidth,

    shallShowOverlay: (state) => state.shallShowOverlay,

    shallShowLoadingAnimation: (state) => state.shallShowLoadingAnimation,

    shallShowLoadingLayout: (state) => state.shallShowLoadingLayout,

    pageDidLoad: (state) => state.pageDidLoad,

    photosToPreview: (state) => state.photosToPreview,

    networkError: (state) => state.networkError,
  },
  mutations: {
    LOAD_PHOTOS_TO_PREVIEW(state, photos) {
      state.photosToPreview = photos
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_LOADING_ANIMATION(state, val) {
      state.shallShowLoadingAnimation =
        val !== undefined ? val : !state.shallShowLoadingAnimation
    },
    TOGGLE_LOADING_LAYOUT(state, val) {
      state.shallShowLoadingLayout =
        val !== undefined ? val : !state.shallShowLoadingLayout
    },

    SET_PAGE_DID_LOAD(state, val) {
      state.pageDidLoad = val
    },

    DISPLAY_NETWORK_ERROR(state, val) {
      if (state.shallShowNetworkError === val) return

      state.shallShowNetworkError = val

      if (state.shallShowNetworkError) {
        state.networkError = 'Offline. Your network is unavailable.'
      } else {
        state.networkError = null
      }
    },
  },
  actions: {},
}
