import { render, staticRenderFns } from "./AppReviewModal.vue?vue&type=template&id=7997dc83&scoped=true"
import script from "./AppReviewModal.vue?vue&type=script&lang=js"
export * from "./AppReviewModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7997dc83",
  null
  
)

export default component.exports