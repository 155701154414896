/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      try {
        if (registration) {
          setInterval(() => {
            registration.update()
          }, 5000)
        }
      } catch (error) {
        console.error('Failed to load service-worker.js', error)
      }
    },
    registered() {
      // console.log('Service worker has been registered.')
    },
    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updatefound() {
      // console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      try {
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
        )
      } catch (error) {
        console.error('Failed to update service-worker.js', error)
      }
    },
    offline() {},
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
