<!-- PWAPrompt.vue -->
<template>
  <b-toast
    id="update-pwa-toast"
    variant="primary"
    title="New version app is available. Refresh now?"
    noAutoHide
    solid
    toaster="b-toaster-bottom-full"
    :visible="updateExists || chunkErrorEncountered"
  >
    <div class="w-100 d-flex justify-content-center align-items-center">
      <b-button
        class="mr-1"
        variant="primary"
        type="button"
        size="sm"
        :disabled="loading"
        @click="handleRefresh"
      >
        Quick Update
      </b-button>

      <b-button
        variant="outline-primary"
        type="button"
        size="sm"
        :disabled="loading"
        @click="dismissPrompt"
      >
        No, thanks
      </b-button>
    </div>
  </b-toast>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formToastification } from '@core/mixins/ui/formToastification'

import { blockServiceWorkerUpdate } from '@/plugins/vue-social-auth/utils'

export default {
  name: 'UpdatePWA',

  components: {
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [formToastification],

  data: () => ({
    loading: false,

    refreshing: false,
    registration: null,
    updateExists: false,
    chunkErrorEncountered: false,

    toastPosition: 'bottom-right',
    logo: require('@/assets/images/logo/logo.svg'),
  }),

  created() {},

  beforeMount() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    document.addEventListener('chunkErrorEncountered', this.handleChunkError, {
      once: true,
    })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },

  methods: {
    dismissPrompt() {
      this.updateExists = false
    },

    makeToast() {
      this.$bvToast.show('update-pwa-toast')
    },

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    handleChunkError() {
      this.chunkErrorEncountered = true
    },

    handleRefresh() {
      if (this.chunkErrorEncountered) {
        this.chunkErrorEncountered = false
        window.location.reload()
        return
      }

      this.updateApp()
    },

    // Called when the user accepts the update
    updateApp() {
      this.updateExists = false

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },

  watch: {},
}
</script>

<style scoped></style>
