export const handleDOMNodeInserted = {
  beforeMount() {
    document.body.addEventListener(
      'DOMNodeInserted',
      (evt) => {
        let node = evt.target
        let images = []

        try {
          images = node.querySelectorAll('img')
        } catch (e) {
          return
        }

        images.forEach((img) => {
          if (img.src && img.src.includes('cloudfront')) {
            img.setAttribute('referrerpolicy', 'origin-when-cross-origin')
            img.setAttribute('crossorigin', 'anonymous')
            // img.setAttribute('loading', 'lazy')

            let src = img.src
            img.removeAttribute('src')
            img.setAttribute('src', src)
          }
        })
      },
      false
    )
  },

  data() {
    return {}
  },
}

export const _ = null
