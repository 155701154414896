import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tutorialsData: {
    // general
    general: {
      icon: 'ChevronRightIcon',
      title: 'General',
      subtitle: 'General information',
      qandA: [
        {
          question: 'What is the purpose of this app?',
          tags: 'purpose role what',
          ans:
            "Agendum is people's virtual agenda which they can share publicly or with custom contact lists they define (family, friends, close friends, work etc.)." +
            ' </br>Agendum aims to provide users with a convenient way to schedule, organize and learn about various activities such as vacations, trips, social gatherings, sports activities, concerts and shows.',
        },
        {
          question: 'How does the app work?',
          tags: 'work how',
          ans:
            'Adding an activity to your calendar is easy: put all the details into one or more agendums and send invitations.' +
            ' Get people to react, buy tickets and join your agendums. Once they have joined, they can chat, confirm participation, vote questions, add links, add photos, hashtags and more.' +
            ' Add the agendum to your collections to build a nice profile.',
        },
        {
          question: 'Is the Agendum app free?',
          tags: 'price pricing fee subscription',
          ans: 'The Agendum app is totally free, but accessing some features might require a premium subscription in the future.',
        },
        {
          question: 'Is the app available on multiple platforms?',
          tags: 'available platform platforms ios android mobile browser',
          ans: 'Yes, the app is designed to be accessible on various platforms. You can use it on your smartphone or tablet by downloading the app from the respective app stores. Additionally, there you can download the app using your browser for a desktop or laptop computer.',
        },
        {
          question: 'What does it mean that the app is in beta?',
          tags: 'beta app',
          ans: 'The beta phase indicates that the app is in its testing and refinement stage. It allows us to gather user feedback, identify and address any issues or bugs, and further enhance the app based on user experiences and suggestions.',
        },
        {
          question: 'How long will the beta phase last?',
          tags: 'beta app phase',
          ans: 'The duration of the beta phase can vary depending on the feedback and improvements needed. We are committed to ensuring a high-quality user experience, so the beta phase may last several months. We will keep our users informed about any updates or changes during this period.',
        },
        {
          question:
            'Can I provide feedback or suggestions during the beta phase?',
          tags: 'beta app feedback suggestion',
          ans: 'Absolutely! We highly encourage users to provide feedback, suggestions, and report any issues they encounter during the beta phase. Your feedback is invaluable in helping us improve the app and make it even better before the official release.',
        },
      ],
    },
    // account
    account: {
      icon: 'ChevronRightIcon',
      title: 'Account',
      subtitle: 'What should I know?',
      qandA: [
        {
          question: 'Can I change my email address?',
          tags: 'email change new',
          ans: 'Yes, you will have to prove you own both email addresses by using the verification codes we send you.',
          // ans: 'Yes, you will have to prove your identity using the auth code sent on the current email address and validate your new email address using the verification code.',
        },
        {
          question: 'What other settings can I manage?',
          tags: 'settings email username password social media account privacy',
          ans: 'You can manage settings such as account (email, username), security (password), social media account, privacy (cookies).',
        },
        {
          question: 'Can I link a social media account?',
          tags: 'account link social media google apple',
          ans: 'Yes, you can register using your Google or Apple account. Also, you can choose to link and manage your social media account using the Social Media section in Settings.',
        },
        {
          question: 'Can I deactivate my account?',
          tags: 'account deactivate',
          ans:
            'Sure, access the general settings to deactivate your account. ' +
            '</br> By deactivating your Agendum account, your data and public profile will no longer be available.' +
            '</br> To revert this action, contact our customer support within 30 days of deactivation. Afterwards, your data will be permanently deleted.',
        },
      ],
    },
    // agendum
    agendum: {
      icon: 'ChevronRightIcon',
      title: 'Agendum',
      subtitle: 'The concept of agendum',
      qandA: [
        {
          question: 'What is an agendum?',
          tags: 'agendum definition',
          ans: 'An agendum is an activity in your calendar. It is a group entity with details and people you invite or allow to join. It has plenty of features that allow you to organize an activity such as: polls, photos, notes, privacy settings and more.',
        },
        {
          question: 'What is an item of an agendum?',
          tags: 'item agendum definition',
          ans: 'An item of an agendum is a one of the sub-activities you have in plan for your agendum. To a travel related agendum, an item might be the activity of going to a beach or visiting a touristic objective.',
        },
        {
          question: 'What is the difference between an agendum and an event?',
          tags: 'agendum event',
          ans: 'While an event represents the general occurrence where you meet with other people, an agendum is about your personal experience of attending that event, alongside the people you go with and all the moments you share together.',
        },
        {
          question: 'How can I join an agendum?',
          tags: 'agendum join invite',
          ans: "You can join an agendum by accepting an invitation notification or by browsing through the agendums on your feed. If you find an agendum that you're interested in and it's open-to-join, simply click the button to join. The agendum members will be notified of your participation.",
        },
        {
          question: 'Can I share an agendum on my feed?',
          tags: 'agendum share feed',
          ans: "Your agendum is automatically shared on your feed when you set its status to 'set'. You can choose to share it manually, by clicking the share button inside your agendum's actions panel. Of course, you can also choose to delete it from feed by simply clicking the delete-from-feed button.",
        },
        {
          question: 'How can I control my agendum privacy?',
          tags: 'agendum privacy settings',
          ans: "All members can set how the agendum shows up on their feed or profile by accessing the 'edit my settings' inside agendum's actions panel.",
        },
      ],
    },
    profile: {
      icon: 'ChevronRightIcon',
      title: 'Profile',
      subtitle: 'Build your profile',
      qandA: [
        {
          question: 'How can I build a travel profile?',
          tags: 'profile travel',
          ans: 'To build and share your travel profile, you can create agendums where you add details about the places you have visited, such as photos, descriptions and recommendations. Your contacts will be able to view your travel profile and engage with your experiences.',
        },
        {
          question: 'How can I build a professional profile?',
          tags: 'profile professional',
          ans: 'To build and share your professional profile, you can create agendums where you add details about your next work activity, such as future concerts and shows, alongside with a description and photos. Your contacts will be able to view your future agendums, buy tickets and even promote them.',
        },
      ],
    },
    network: {
      icon: 'ChevronRightIcon',
      title: 'Contacts & Invitations',
      subtitle: 'Build your network',
      qandA: [
        {
          question: 'Can I follow the people I know?',
          tags: 'follower following',
          ans: 'Sure, you can follow anyone you would like to. You will be able to see only their public agendums in your feed.',
        },
        {
          question: 'Can I add someone as my contact?',
          tags: 'contact list network follow',
          ans: 'Yes, you can add someone as a contact as long as they follow you. Having someone as a contact means you know that person and that person knows you. You can add your contacts in custom lists you define.',
        },
        {
          question: 'Can I create a contact list?',
          tags: 'contact list move drag drop',
          ans: 'Sure, you can do that by accessing your contact lists page. You can also choose to incorporate one list within another (drag and drop), as you wish to organize your contacts.',
        },
        {
          question: 'Can I invite people to join my agendum?',
          tags: 'invite invitation agendum',
          ans: 'Sure, you can invite people to join your agendum as long as they are followers or contacts.',
        },
        {
          question: 'How can I join Agendum?',
          tags: 'invite invitation app agendum register',
          ans: "Currently Agendum operates on an invitation-only basis. To join, you'll need a valid invitation code.",
        },
        {
          question: 'Can I invite people to join me on Agendum?',
          tags: 'invite invitation app agendum register',
          ans:
            "Sure, you can simply click the 'Invite Friends' at the bottom of the app menu (left side). " +
            '</br>Please note that you have a limited number of invitations, which expire in 24 h once generated. ' +
            '</br> If an invitation is not used, it will be added back to your account.',
        },
      ],
    },
    collection: {
      icon: 'ChevronRightIcon',
      title: 'Collections',
      subtitle: 'Create your own collections',
      qandA: [
        {
          question: 'What is a collection?',
          tags: '',
          ans: 'A collection is a group of agendums which can represent a concept or a hobby, such as: festivals, sports or even travel destinations: Barcelona, Bali, Rome etc.',
        },
        {
          question: 'How can I add agendums in my collections?',
          tags: '',
          ans: "You can add an agendum to multiple collections by accessing the 'edit my settings' inside agendum's actions panel.",
        },
      ],
    },
    // cancellation and return
    privacy: {
      icon: 'ChevronRightIcon',
      title: 'Privacy & Security',
      subtitle: 'Learn more about our system',
      qandA: [
        {
          question: 'How does privacy work in the app?',
          tags: '',
          ans: 'Privacy is a top priority in the app. You have flexible control over who can see your agendums and profile. You can choose to share agendums only with specific contact lists or keep them private.',
        },
      ],
    },
  },
}

mock.onGet('/tutorials/data').reply((config) => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.tutorialsData).forEach((entry) => {
    const [categoryName, categoryObj] = entry
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter((qAndAObj) => {
      return (
        qAndAObj.question.toLowerCase().includes(queryLowered) ||
        qAndAObj.tags.toLowerCase().includes(queryLowered)
      )
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = {
        ...categoryObj,
        qandA: filteredQAndAOfCategory,
      }
    }
  })

  return [200, filteredData]
})
