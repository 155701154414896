<!-- PWAPrompt.vue -->
<template>
  <b-modal
      :ref="modalName"
      :id="modalName"
      title="Help us improve!"
      centered
      hide-footer
      size="md"
      class="fgModal"
  >
    <div class="">
      <h6 class="">You’re currently using the beta version of the app — always getting better thanks to you!</h6>
      <app-rating show-cancel-btn
                  @formSubmitted="handleFormSubmitted"
                  @cancelAction="handleCancelAction"
      />
    </div>
  </b-modal>
</template>

<script>
import {BButton, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {formToastification} from '@core/mixins/ui/formToastification'

import AppRating from "@/components/settings/AppRating";
import {mapGetters} from "vuex";

export default {
  name: 'AppReviewModal',

  props: {
    modalName: {
      type: String,
      default: 'modal-app-review',
    },
  },

  components: {
    BButton,

    AppRating,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [formToastification],

  data: () => ({
    loading: false,

    refreshing: false,
    registration: null,
    updateExists: false,
    chunkErrorEncountered: false,

    toastPosition: 'bottom-right',
    logo: require('@/assets/images/logo/logo.svg'),
  }),

  beforeMount() {
    this.init()
  },

  mounted() {
    // this.openModal()
  },

  methods: {
    closeModal() {
      this.$refs[this.modalName].hide()
    },

    openModal() {
      this.$refs[this.modalName].show()
      this.$store.commit('appReviews/setPageCounter', 0)
    },

    init() {
      setTimeout(() => {
        if (!this._isDestroyed && this.user.data.rating == null && (_.isEmpty(this.appReviewCookie) || this.appReviewCookie.consent !== false) && this.pageCounter > 3 && this.$router.currentRoute.name === 'home') {
          this.openModal()
        } else if (!this._isDestroyed && this.user.data.rating == null && (_.isEmpty(this.appReviewCookie) || this.appReviewCookie.consent !== false)) {
          this.init()
        }
      }, 3000)
    },

    setAppReviewCookie() {
      this.$cookies.set('agendum_app_review', {
        consent: false,
      })
    },

    handleCancelAction() {
      this.setAppReviewCookie()
      this.closeModal()
    },

    handleFormSubmitted() {
      this.setAppReviewCookie()
      this.closeModal()
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('appReviews', ['pageCounter']),

    appReviewCookie() {
      return this.$cookies.get('agendum_app_review')
    },
  },

  watch: {},
}
</script>

<style scoped></style>
