import Form from '@/plugins/Form'
import store from '@/store'

const defaultEndpoint = '/settings'

const state = {
  coverTemplates: [],
  activityTemplates: {},
  featuredActivityTemplates: {},
}

const getters = {
  coverTemplates: (state) => {
    return state.coverTemplates
  },
  activityTemplates: (state) => {
    return state.activityTemplates
  },
  featuredActivityTemplates: (state) => {
    return state.featuredActivityTemplates
  },
}

const actions = {
  async fetchCoverTemplates({ commit }, payload) {
    let endpoint = `${defaultEndpoint}/cover-templates`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setCoverTemplates', null)
      return response
    }

    commit('setCoverTemplates', response.data.data)
    return response
  },

  async actionFetchActivityTemplates({ commit }, payload) {
    let endpoint = `${defaultEndpoint}/activity-templates`
    let searchQueryRequest = payload.search && payload.search.trim() !== ''

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (searchQueryRequest) {
      return response
    }

    if (response.data == null || response.data.data == null) {
      if (payload.featured != null) {
        commit('setFeaturedActivityTemplates', null)
      } else {
        commit('setActivityTemplates', null)
      }
      return response
    }

    if (payload.featured != null) {
      commit('setFeaturedActivityTemplates', response.data)
    } else {
      commit('setActivityTemplates', response.data)
    }

    return response
  },

  async updateGeneralSettings({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/general`)
  },

  async validateGeneralSettings({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/general/validate`)
  },

  async checkUsername({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/general/check-username`)
  },

  async updateProfileInformation({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/profile-information`)
  },

  async updateSocialLinks({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/social-links`)
  },

  async updateSessionTimezone({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/session`)
  },

  async updateSessionPushNotificationSetting({ dispatch, commit }, data) {
    let form = new Form(data, this._vm.$api, store)
    await form.post(`${defaultEndpoint}/push-notifications`)
    return form
  },

  async updateSessionStateSetting({ dispatch, commit }, data) {
    let form = new Form(data, this._vm.$api, store)
    return form.post(`${defaultEndpoint}/session`)
  },

  async updateAppConfigs({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/app`)
  },

  async deactivateAccount({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/deactivate-account`)
  },
}

const mutations = {
  setCoverTemplates(state, data = []) {
    state.coverTemplates = data
  },

  setActivityTemplates(state, data = []) {
    let dataToSet = null

    if (data != null) {
      dataToSet = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    state.activityTemplates = dataToSet
  },

  setFeaturedActivityTemplates(state, data = []) {
    let dataToSet = null

    if (data != null) {
      dataToSet = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    state.featuredActivityTemplates = dataToSet
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
